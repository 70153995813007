import { application } from "./application";

import AlertController from "./alert_controller";
import ActiveLinkController from "./active_link_controller";
import AddressController from "./address_controller";
import AirDatepickerController from "./air_datepicker_controller";
import CollapsibleFilterController from "./collapsible_filter_controller";
import CopyClipboardController from "./components/copy_clipboard_controller";
import CustomBannerController from "./custom_banner";
import DropdownController from "./dropdown_controller";
import FavoriteController from "./favorite";
import FormController from "./form_controller";
import FormValidatorController from "./form_validator/index";
import GtmController from "./gtm_controller";
import HeaderController from "./header_controller";
import ImageLazyLoaderController from "./image_lazy_load_controller";
import LinkController from "./link_controller";
import NotificationsController from "./notifications_controller";
import ModalController from "./modal_controller";
import PhoneInputIntlController from "./phone_input_intl_controller";
import StepperController from "./stepper_controller";
import RemoveDomElementController from "./remove_dom_element";
import RevealController from "./reveal";
import PasswordStrengthController from "./password_strength_controller";
import PasswordVisibilityController from "./password_visibility_controller";
import SliderController from "./slider_controller";
import StripeController from "./stripe_controller";
import TabsController from "./tabs_controller";

// Appointments
import CreateAppointmentController from "./appointments/create_appointment_controller";

// Components
import SearchSelectController from "./components/search_select_controller";

// Tickets
import NewTicketController from "./tickets/new_ticket_controller";
import UploadCsvTicketController from "./tickets/upload_csv_ticket_controller";
import RecentProductSearchesController from "./tickets/recent_product_searches_controller";

// Reports
import ReportsWithdrawnController from "./reports/withdrawn_controller";

// Registration
import RegistrationsController from "./registrations_controller";

// Consignor Payments
import ConsignorNegativeBalancePaymentsController from "./consignor_negative_balance_payments_controller";

application.register("search-select", SearchSelectController);

application.register("alert", AlertController);

application.register("address", AddressController);
application.register("air-datepicker", AirDatepickerController);
application.register("collapsible-filter", CollapsibleFilterController);
application.register("copy-clipboard", CopyClipboardController);
application.register("custom-banner", CustomBannerController);
application.register("dropdown", DropdownController);
application.register("favorite", FavoriteController);
application.register("form-validator", FormValidatorController);
application.register("form", FormController);
application.register("gtm", GtmController);
application.register("header", HeaderController);
application.register("image-lazy-loader", ImageLazyLoaderController);
application.register("link", LinkController);
application.register("modal", ModalController);
application.register("notifications", NotificationsController);
application.register("phone-input-intl", PhoneInputIntlController);
application.register("stepper", StepperController);
application.register("turbo-active-link", ActiveLinkController);
application.register("remove-dom-element", RemoveDomElementController);
application.register("reveal", RevealController);
application.register("password-strength", PasswordStrengthController);
application.register("password-visibility", PasswordVisibilityController);
application.register("slider", SliderController);
application.register("stripe", StripeController);
application.register("tabs", TabsController);

// Appointments
application.register("create-appointment", CreateAppointmentController);

// Tickets
application.register("new-ticket", NewTicketController);
application.register(
  "ticket-recent-product-searches",
  RecentProductSearchesController
);
application.register("upload-csv-ticket", UploadCsvTicketController);

// Reports
application.register("reports-withdrawn", ReportsWithdrawnController);

// Registrations
application.register("registrations", RegistrationsController);

// Consignor Payments
application.register(
  "consignor-negative-balance-payments",
  ConsignorNegativeBalancePaymentsController
);
