import { Controller } from "@hotwired/stimulus";
import { APIClient } from "../config/https";
import GtmController from "./gtm_controller";

// Connects to data-controller='favorite'
export default class extends Controller {
  static classes = ["favorite"];

  static values = { sgp: String, url: String };

  initialize() {
    this.gtm = new GtmController(this);
  }

  toggle(event) {
    event.preventDefault();
    this.icon = event.target.closest("svg");

    const isFavorite = this.icon.classList.contains(this.favoriteClass);
    this.trackEvent(isFavorite);

    if (isFavorite) {
      this.icon.classList.remove(this.favoriteClass);
      this.remove();
    } else {
      this.icon.classList.add(this.favoriteClass);
      this.add();
    }
  }

  trackEvent(isFavorite) {
    const gtmParams = {
      gtmAction: isFavorite ? "favorites_remove_click" : "favorites_add_click",
      gtmLabel: isFavorite ? "favorites_remove" : "favorites_add",
      gtmValue: this.sgpValue
    };

    this.gtm.track({ gtmCategory: "browse_supply", ...gtmParams });
  }

  add() {
    const data = { sgp: this.sgpValue };

    const requestOptions = {
      method: "POST",
      body: JSON.stringify(data)
    };

    APIClient(`${this.urlValue}`, requestOptions);
  }

  remove() {
    const requestOptions = { method: "DELETE" };

    APIClient(`${this.urlValue}/${this.sgpValue}`, requestOptions);
  }
}
