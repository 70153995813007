import { Controller } from "@hotwired/stimulus";
import { APIClient } from "../config/https";
import { loadStripe } from "@stripe/stripe-js";
import { FakeStripeHook, getStripeMode } from "./fake_stripe_hook";

const MAPPED_ERRORS = {
  paymentMethodPreviouslyUsedWithoutAttached: /The provided PaymentMethod was previously used with a PaymentIntent without Customer attachment/i,
};

const messageFormatter = (message) => {
  if (
    message?.match(MAPPED_ERRORS.paymentMethodPreviouslyUsedWithoutAttached)
  ) {
    return "We're having a problem with the credit card on file. Please re-add or update your credit card.";
  }

  return message;
};

// Connects to data-controller="stripe"
export default class extends Controller {
  static targets = [
    "cardName",
    "cardNumber",
    "cardNumberLabel",
    "cardNumberErrors",
    "cardExpiry",
    "cardExpiryLabel",
    "cardExpiryErrors",
    "cardCvc",
    "cardCvcLabel",
    "cardCvcErrors",
    "form",
    "errors",
    "submitButton",
    "paymentIntentId",
    "useSavedCard",
    "updateCreditCardOnFile",
    "idempotencyKey",
    "savedCardSourceId",
  ];

  static values = {
    genericMessage: String,
    featureFlags: { type: Object, default: null },
  };

  initialize() {
    this.cssLabelClasses = ["sg-base-input-label-position", "text-xss"];
    this.elements = {
      cardNumber: {
        label: this.cardNumberLabelTarget,
        filled: false,
        focus: false,
      },
      cardCvc: {
        label: this.cardCvcLabelTarget,
        filled: false,
        focus: false,
      },
      cardExpiry: {
        label: this.cardExpiryLabelTarget,
        filled: false,
        focus: false,
      },
    };
  }

  async connect() {
    this.stripe = await loadStripe(process.env.STRIPE_PUBLISHABLE_KEY);

    const elements = this.stripe.elements();

    this.cardNumber = elements.create("cardNumber", this.styles);
    this.cardNumber.mount(this.cardNumberTarget);
    this.setElementCallbacks(this.cardNumber, "cardNumber");

    this.cardExpiry = elements.create("cardExpiry", this.styles);
    this.cardExpiry.mount(this.cardExpiryTarget);
    this.setElementCallbacks(this.cardExpiry, "cardExpiry");

    this.cardCvc = elements.create("cardCvc", this.styles);
    this.cardCvc.mount(this.cardCvcTarget);
    this.setElementCallbacks(this.cardCvc, "cardCvc");
  }

  setElementCallbacks(mainTargetElement, elementKey) {
    mainTargetElement.on("change", ({ empty, error, ...other }) => {
      this.setFilled(elementKey, empty);
      this.setError(elementKey, error);
    });
    mainTargetElement.on("focus", () => {
      this.setFocus(elementKey, true);
    });
    mainTargetElement.on("blur", () => {
      this.setFocus(elementKey, false);
    });
  }

  setFilled(key, empty) {
    this.elements = {
      ...this.elements,
      [key]: {
        ...this.elements[key],
        filled: !empty,
      },
    };

    this.setSelected();
  }

  setFocus(key, focus) {
    this.elements = {
      ...this.elements,
      [key]: {
        ...this.elements[key],
        focus: focus,
      },
    };

    this.setSelected();
  }

  setSelected(field, force = false) {
    if (force && field) {
      this.addStylesToLabel(this.elements[field].label);

      return;
    }

    this.setElementsLabelStyles();
  }

  setError(elementKey, error) {
    this[`${elementKey}ErrorsTarget`].textContent = error ? error.message : "";
  }

  setElementsLabelStyles() {
    const labels = Object.keys(this.elements);

    labels.forEach((k) => {
      const { label, filled, focus } = this.elements[k];

      if (focus || filled) {
        this.addStylesToLabel(label);
      } else {
        this.removeStylesFromLabel(label);
      }
    });
  }

  addStylesToLabel(label) {
    label.classList.add(...this.cssLabelClasses);
  }

  removeStylesFromLabel(label) {
    label.classList.remove(...this.cssLabelClasses);
  }

  handleError(errorMessage) {
    this.errorsTarget.textContent = messageFormatter(errorMessage);
    this.handleSubmitButton(false);
  }

  handleSubmitButton(disabled) {
    this.submitButtonTarget.disabled = disabled;
  }

  handleGenericErrorMessage() {
    this.handleError(this.genericMessageValue);
  }

  useSavedCardChanged() {
    this.cardNameTarget.required = !this.useExistingCard;
  }

  async createStripeRequest(client_secret) {
    const stripeMode = getStripeMode(this.featureFlagsValue);

    return stripeMode === "normal"
      ? this.stripe.confirmCardPayment(client_secret, {
          setup_future_usage: "off_session",
          payment_method: this.paymentMethod,
        })
      : FakeStripeHook.getInstance().confirmCardPayment(stripeMode);
  }

  get billingAddress() {
    const form = new FormData(this.formTarget);

    return {
      name: [
        form.get("address[first_name]"),
        form.get("address[last_name]"),
      ].join(" "),
      address: {
        line1: form.get("address[addr_line_1]"),
        line2: form.get("address[addr_line_2]"),
        city: form.get("address[city]"),
        country: form.get("address[country]"),
        state: form.get("address[state]"),
        postal_code: form.get("address[zip]"),
      },
    };
  }

  get styles() {
    return {
      hideIcon: true,
      style: {
        base: {
          iconColor: "#c4f0ff",
          color: "#131313",
          fontWeight: "500",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontSize: "16px",
          fontSmoothing: "antialiased",
          ":-webkit-autofill": {
            color: "#131313",
          },
          "::placeholder": {
            color: "transparent",
          },
        },
        invalid: {
          iconColor: "#131313",
          color: "#131313",
        },
      },
    };
  }

  get paymentMethod() {
    return this.useExistingCard
      ? this.savedCardSourceIdTarget.value
      : { card: this.cardNumber, billing_details: this.billingAddress };
  }

  get useExistingCard() {
    return this.hasUseSavedCardTarget && this.useSavedCardTarget.checked;
  }

  get updateCreditCardOnFile() {
    return (
      this.hasUpdateCreditCardOnFileTarget &&
      this.updateCreditCardOnFileTarget.checked
    );
  }
}
