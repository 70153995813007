import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="registrations"
export default class extends Controller {
  static targets = [
    "birthDate",
    "businessCheckbox",
    "companyField",
    "companyInput",
    "guardianFirstName",
    "guardianLastName",
    "guardianEmail",
  ];

  toggleAccountType() {
    if (this.businessCheckboxTarget.checked) {
      this.companyFieldTarget.classList.remove("hidden");
      this.companyInputTarget.required = true;
    } else {
      this.companyFieldTarget.classList.add("hidden");
      this.companyInputTarget.required = false;
    }
  }

  updateGuardianFields() {
    const isUnder18 = this.under18();
    if (isUnder18) {
      this.displayGuardianFields();
    } else {
      this.hideGuardianFields();
    }

    this.updateGuardianValidation(isUnder18);
  }

  displayGuardianFields() {
    this.guardianFirstNameTarget.classList.remove("hidden");
    this.guardianLastNameTarget.classList.remove("hidden");
    this.guardianEmailTarget.classList.remove("hidden");
  }

  hideGuardianFields() {
    this.guardianFirstNameTarget.classList.add("hidden");
    this.guardianLastNameTarget.classList.add("hidden");
    this.guardianEmailTarget.classList.add("hidden");
  }

  updateGuardianValidation(required) {
    this.guardianFirstNameTarget.querySelector("input").required = required;
    this.guardianLastNameTarget.querySelector("input").required = required;
    this.guardianEmailTarget.querySelector("input").required = required;
  }

  under18() {
    const birthDate = new Date(this.birthDateTarget.value);
    const currentDate = new Date();
    const diff = currentDate - birthDate;
    const age = Math.floor(diff / 31557600000);

    return age < 18;
  }
}
