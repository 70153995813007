import { Controller } from "@hotwired/stimulus";
import { APIClient } from "../config/https";

// Connects to data-controller="address"
export default class extends Controller {
  static targets = ["saved", "form", "confirm", "errors", "saveButton"];

  static values = {
    updateAddressPath: String,
    validateAddressPath: String,
    loadStatesPath: String,
  };

  enterDifferentAddress() {
    this.savedTarget.classList.add("hidden");
    this.confirmTarget.classList.add("hidden");

    this.errorsTarget.classList.remove("hidden");
    this.showForm();
  }

  cancel() {
    this.confirmTarget.classList.add("hidden");
    this.savedTarget.classList.remove("hidden");
    this.errorsTarget.classList.add("hidden");
    this.hideForm();
  }

  showForm() {
    this.formTargets.forEach((e) => e.classList.remove("hidden"));
    if (this.hasSaveButtonTarget) {
      this.saveButtonTarget.removeAttribute("disabled");
    }
  }

  hideForm() {
    this.formTargets.forEach((e) => e.classList.add("hidden"));
  }

  validate(e) {
    if (!this.form.checkValidity()) return false;
    e.target.disabled = true;

    return APIClient(this.validateAddressPathValue, {
      method: "POST",
      body: JSON.stringify(this.currentAddress),
    })
      .then((response) => response.text())
      .then((html) => Turbo.renderStreamMessage(html))
      .then(() => {
        setTimeout(() => {
          if (document.getElementsByClassName("address-error").length > 0) {
            this.saveButtonTarget.removeAttribute("disabled");
          } else {
            this.hideForm();
            this.confirmTarget.classList.remove("hidden");
          }
        }, 100);
      });
  }

  save(e) {
    if (!this.form.checkValidity()) return false;
    e.target.disabled = true;

    const { address } =
      this.selectedAddress === "suggestion_ignored"
        ? this.currentAddress
        : this.suggestedAddress;

    return APIClient(this.updateAddressPathValue, {
      method: "POST",
      body: JSON.stringify({
        address: {
          address_status: this.selectedAddress,
          ...address,
        },
      }),
    })
      .then((response) => response.text())
      .then((html) => Turbo.renderStreamMessage(html));
  }

  loadStates(e) {
    const { value } = e.target;

    const params = { country: value };
    const searchParams = new URLSearchParams(params);

    return APIClient(this.loadStatesPathValue + `?${searchParams.toString()}`, {
      method: "GET",
    })
      .then((response) => response.text())
      .then((html) => Turbo.renderStreamMessage(html));
  }

  get form() {
    return document.getElementById("address-form");
  }

  get formData() {
    return new FormData(this.form);
  }

  get selectedAddress() {
    return this.formData.get("address_validation_selection");
  }

  get currentAddress() {
    const form = this.formData;

    return {
      address: {
        first_name: form.get("address[first_name]"),
        last_name: form.get("address[last_name]"),
        addr_line_1: form.get("address[addr_line_1]"),
        addr_line_2: form.get("address[addr_line_2]"),
        city: form.get("address[city]"),
        country: form.get("address[country]"),
        state: form.get("address[state]"),
        zip: form.get("address[zip]"),
        phone_number: form.get("address[phone_number]"),
      },
    };
  }

  get suggestedAddress() {
    const form = this.formData;

    return {
      address: {
        first_name: form.get("suggested_address[first_name]"),
        last_name: form.get("suggested_address[last_name]"),
        addr_line_1: form.get("suggested_address[addr_line_1]"),
        addr_line_2: form.get("suggested_address[addr_line_2]"),
        city: form.get("suggested_address[city]"),
        country: form.get("suggested_address[country]"),
        state: form.get("suggested_address[state]"),
        zip: form.get("suggested_address[zip]"),
        phone_number: form.get("suggested_address[phone_number]"),
      },
    };
  }
}
