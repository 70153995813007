import { Controller } from "@hotwired/stimulus";
import { useDebounce } from "stimulus-use";
import GtmController from "./gtm_controller";

// Connects to data-controller="form"
export default class extends Controller {
  static debounces = [
    { name: "instantSubmit", wait: 100 },
    { name: "debouncedSubmit", wait: 1500 }
  ];

  initialize() {
    this.gtm = new GtmController(this);
  }

  connect() {
    useDebounce(this, { wait: 1500 });
  }

  submit(event) {
    this.element.requestSubmit();
    this.trackOnSubmit(event);
  }

  instantSubmit(event) {
    this.submit(event);
  }

  debouncedSubmit(event) {
    this.submit(event);
  }

  trackOnSubmit(event) {
    const { gtmAction, gtmCategory, gtmEnabled, gtmLabel, gtmValue } = event.target.dataset;

    if (!gtmEnabled || !gtmAction || !gtmCategory || !gtmLabel) return;

    const gtmParams = {
      gtmAction: gtmAction,
      gtmCategory: gtmCategory,
      gtmLabel: gtmLabel,
      gtmValue: gtmValue || event.target.value,
    };

    this.gtm.track(gtmParams);
  }
}
