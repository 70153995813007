import { Turbo } from "@hotwired/turbo-rails";
import tippy from "tippy.js";
import "smartbanner.js";

import "./controllers";

import components from "./components/**.js";
import "./braze_integration";

Turbo.session.drive = false;
Turbo.cache.exemptPageFromCache();
Turbo.session.disable();

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("smartbanner.js");

// https://atomiks.github.io/tippyjs/v6/constructor/
window.addEventListener("load", () => {
  tippy("[data-tippy-click]", { trigger: "click" });
  tippy("[data-tippy-hover]");
});
document.addEventListener("turbo:frame-render", () => {
  tippy("[data-tippy-click]", { trigger: "click" });
  tippy("[data-tippy-hover]");
});

// Application JS
window.packExports = window.packExports || {};

import ReactRailsUJS from "react_ujs";
const componentsContext = {};
components.forEach((component) => {
  const cmp = component.default;
  componentsContext[cmp.name] = cmp;
});

ReactRailsUJS.getConstructor = (name) => {
  return componentsContext[name];
};

ReactRailsUJS.handleEvent("turbo:frame-load", ReactRailsUJS.handleMount);
ReactRailsUJS.handleEvent("turbo:frame-render", ReactRailsUJS.handleUnmount);
